<template>
  <div
    class="container size-mobile bg-element-white p-0 min-height-second d-flex align-items-center"
    style="margin-top:0px;min-height: calc(100vh);"
  >
  <div id="overlay" v-if="progressLogin">
        <span class="d-flex justify-content-center align-items-center" style="height: -webkit-fill-available;color:white">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div> Sedang Login...
        </span>
    </div>
    <div class="container">
      <v-dialog/>
      <div class="col-md-12 p-1 text-center">
        <img src="../assets/img/mmui.jpg" alt="CheckIn" />
      </div>
      <div class="col-md-12 p-1"> 

        <div class="mb-4">
          <form class="needs-validation" method="POST" @submit.prevent="store" novalidate>
            <fieldset class="mt-3" :class="{'is-invalid': $v.username.$error}">
              <legend class="legend-input" :class="{'is-invalid': $v.username.$error}">NIK</legend>
              <input
                type="text"
                name="no_kerja"
                class="form-control"
                placeholder="Nomor NIK/KTP"
                autocomplete="off"
                v-model.trim="$v.username.$model" 
                :class="{'is-invalid': $v.username.$error, 'is-valid':!$v.username.$invalid}"
              />
            <div class="invalid-feedback">
              <span v-if="!$v.username.required">No.Kerja Harus diisi</span>
              <span v-if="!$v.username.minLength">No.Kerja harus minimal 5 karakter</span>
            </div>
            </fieldset>
            <fieldset class="mt-3" :class="{'is-invalid': $v.password.$error}">
              <legend class="legend-input" :class="{'is-invalid': $v.password.$error}">Password</legend>
              <input
                type="password"
                name="password"
                placeholder="Password"
                class="form-control"
                autocomplete="off" 
                v-model.trim="$v.password.$model" 
                :class="{'is-invalid': $v.password.$error, 'is-valid':!$v.password.$invalid}"
              />
              <!-- <div class="valid-feedback">Good</div> -->
              <div class="invalid-feedback">
                <span v-if="!$v.password.required">Password Harus diisi</span>
                <span v-if="!$v.password.minLength">Password minimal 5 karakter</span>
              </div>
            </fieldset>

            <div class="d-flex justify-content-center mb-4 mt-4">
              <button type="submit" class="btn btn-login w-100" :class="{'disabled': isLoading}">Masuk</button>
            </div>
          </form>
          <div class="text-center" style="position:absolute;height:20px;width:100%;">
            Powered By <b>JNIUS</b>
        </div>
          <v-dialog/>
          <alert-error ref="ae"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios"; 
import {required,minLength} from 'vuelidate/lib/validators'
import Api from '../Api'
import alertError from "../components/AlertError.vue";

export default {
  name: "login",
  components: {
    alertError
  },
  data() {
    return { 
      username: "",
      password: "",   
      isLoading: false,    
      progressLogin: false
    };
  },

  async mounted() {
    
	// Cek jika ada token akan redirect ke index
    if (localStorage.getItem('token')) {
      this.$router.push(this.$route.query.redirect || '/')
    }
  },
  validations:{
    username:{
      required,
      minLength: minLength(2), 
    },
    password:{
      required,
      minLength: minLength(5), 
    }
  },
  methods: {
    show () {
        this.$modal.show('dialog', {
        title: 'Maaf',
        text: 'No Kerja Atau Password Salah',
        buttons: [
          {
            title: 'Tutup',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
        ]
      })
    },
    hide () {
        this.$modal.hide('my-first-modal');
    },
    async store() { 

      this.$v.$touch()
      const form = {
        username:  this.username,
        password: this.password 
      }
      
      try { 
        this.progressLogin = true  
        this.isLoading = true  
        const response = await Api.post("auth/login",form);
        // Jika login berhasil
        console.log(response)
        if(response.data.status ==200 && response.data.me.role == 'satpam'){
          localStorage.setItem('cred', JSON.stringify(response.data.me));
          localStorage.setItem('token', JSON.stringify(response.data.token));
          
          // setTimeout(()=>{
            this.$router.push(this.$route.query.redirect || '/')
          // },1200);
          
          
        }else{
          // Jika gagal
          this.show()
          this.isLoading = false
          this.progressLogin = false
          // console.log(response.data)
        }   
      } catch (error) {
        if(error){
          this.$refs.ae.showAlert()
        }
        this.progressLogin = false
        this.isLoading = false
        // console.log(error)
      }
      this.progressLogin = false
      this.isLoading = false
    },
  }, 
};
</script>
